import React from "react";
import styled from "styled-components";

const Container = styled.section`
	background: #1a172c;
`;

const ContentBox = styled.div`
	max-width: 1280px;
	margin: 0 auto;
	padding: 44px 20px;

	@media screen and (max-width: 500px) {
		padding: 36px 20px;
	}
`;

const Heading = styled.h1`
	color: #fff;
	font-size: 42px;
	font-weight: 500;
	line-height: 48px;
	text-align: center;

	@media screen and (max-width: 500px) {
		font-size: 24px;
		line-height: 28px;
	}
`;

const GridBox = styled.div`
	margin-top: 56px;
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
	gap: 42px;
`;

const Cards = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
`;

const CardHeading = styled.h2`
	font-size: 36px;
	font-weight: 600;
	line-height: 42px;
	color: #fff;
`;

const CardDesc = styled.p`
	font-size: 16px;
	font-weight: 400;
	line-height: 26px;
	margin-top: 24px;
	color: #f5faff;

	span {
		font-weight: 700;
	}
`;

function Coverage() {
	return (
		<Container>
			<ContentBox>
				<Heading>Always adding coverage</Heading>
				<GridBox>
					<Cards>
						<CardHeading>95%+</CardHeading>
						<CardDesc>
							Authenticate covers more than 95% of US population and it
							is only growing!
						</CardDesc>
					</Cards>
					<Cards>
						<CardHeading>100k+</CardHeading>
						<CardDesc>
							100k new records added every day to our databases and they
							are added every <span>60 seconds</span>
						</CardDesc>
					</Cards>
					<Cards>
						<CardHeading>24 hours</CardHeading>
						<CardDesc>
							Alerts are delivered within 24 hours of a record becoming
							public
						</CardDesc>
					</Cards>
					<Cards>
						<CardHeading>50 states</CardHeading>
						<CardDesc>
							Our databases have reported sex offender data from 50
							states
						</CardDesc>
					</Cards>
				</GridBox>
			</ContentBox>
		</Container>
	);
}

export default Coverage;
