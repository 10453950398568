import React from 'react';
import styled from 'styled-components';
import SafetyIcon from '../../../images/product-assets/safety.svg';
import SafetyGraphic from '../../../images/product-assets/tcm_safety_image.svg';
import RiskIcon from '../../../images/product-assets/reduce_risk.svg';
import RiskGraphic from '../../../images/product-assets/tcm-value-and-risk-image.svg';
import Ellipse2 from '../../../images/product-assets/Ellipse2.svg';
import Ellipse3 from '../../../images/product-assets/Ellipse3.svg';

const Container = styled.section`
    position: relative;
    overflow: hidden;
`;

const ContentBox = styled.div`
    z-index: 1;
    max-width: 1280px;
    margin: 0 auto;
    padding: 100px 20px 0;

    @media screen and (max-width: 500px) {
        padding: 54px 16px 0;
    }
`;

const Heading = styled.h1`
    color: #333;
    font-size: 42px;
    font-weight: 500;
    line-height: 48px;
    text-align: center;

    @media screen and (max-width: 500px) {
        font-size: 24px;
        line-height: 28px;
    }
`;

const GridBox = styled.div`
    margin-top: 72px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 44px;

    @media screen and (max-width: 500px) {
        grid-template-columns: 1fr;
        margin-top: 36px;
    }
`;

const Cards = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background: #fff;

    @media screen and (max-width: 500px) {
        justify-content: center;
        align-items: center;
    }
`;

const Icons = styled.img`
    width: 65px;
    height: 65px;

    @media screen and (max-width: 500px) {
        display: none;
    }
`;

const Graphic = styled.img`
    margin-top: 16px;
    margin-bottom: 16px;
    width: 100%;
`;

const CardHeading = styled.p`
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    color: #333;
    margin-top: 32px;

    @media screen and (max-width: 500px) {
        margin-top: 0;
    }
`;

const CardDesc = styled.p`
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #52525b;
    margin-top: 8px;

    @media screen and (max-width: 500px) {
        text-align: center;
    }
`;

const EllipseOne = styled.img`
    position: absolute;
    left: -8%;
    bottom: 0;
    z-index: -1;

    @media screen and (max-width: 500px) {
        display: none;
    }
`;

const EllipseTwo = styled.img`
    position: absolute;
    right: -5%;
    bottom: 10%;
    z-index: -1;

    @media screen and (max-width: 500px) {
        display: none;
    }
`;

function HelpInfo() {
    return (
        <Container>
            <ContentBox>
                <Heading>Ways we help you</Heading>
                <GridBox>
                    <Cards>
                        <Icons src={SafetyIcon} alt="Safety Icon" />
                        <CardHeading>Safety</CardHeading>
                        <CardDesc>
                            Continuous court access monitoring makes communities safer.
                        </CardDesc>
                        <Graphic src={SafetyGraphic} alt="Safety Graphic" />
                        <CardDesc>
                            Help your clients protect their users, individuals and customers by
                            keeping bad actors out. Proactive notifications help you mitigate the
                            risks.
                        </CardDesc>
                    </Cards>
                    <Cards>
                        <Icons src={RiskIcon} alt="Risk Icon" />
                        <CardHeading>Add value. Reduce risk.</CardHeading>
                        <CardDesc>
                            Essential to everyday business, not just when a new user joins.
                        </CardDesc>
                        <Graphic src={RiskGraphic} alt="Risk Graphic" />
                        <CardDesc>
                            TCM makes actionable court monitoring accessible, easy to deploy, and
                            simple to maintain. Set it and forget it. We’ll tell you when there’s
                            something you need to know when it happens.
                        </CardDesc>
                    </Cards>
                </GridBox>
            </ContentBox>
            <EllipseOne src={Ellipse3} alt="Ellipse 3" />
            <EllipseTwo src={Ellipse2} alt="Ellipse 2" />
        </Container>
    );
}

export default HelpInfo;
